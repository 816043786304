export class Account {
  constructor(accountData: any) {
    this.id = accountData["mvdsCustomerID"];
    this.userId = accountData["primaryUser"]["id"];
    this.routingNo = accountData["routingNo"];
    this.accountName = accountData["accountName"];
    this.accountNo = accountData["accountNo"];
    this.accountLastUpdateDate =
      accountData["accountDate"].slice(5, 7) +
      "-" +
      accountData["accountDate"].slice(8, 10) +
      "-" +
      accountData["accountDate"].slice(0, 4);
    if (accountData["lastLogin"]) {
      this.lastLogin =
        accountData["lastLogin"].slice(5, 7) +
        "-" +
        accountData["lastLogin"].slice(8, 10) +
        "-" +
        accountData["lastLogin"].slice(0, 4);
    } else {
      this.lastLogin = "";
    }
    this.displayDescription = accountData["displayDescription"];

    if (Object.prototype.hasOwnProperty.call(accountData, "primaryUser")) {
      this.username = accountData["primaryUser"]["username"];
      this.firstName = accountData["primaryUser"]["fullname"].split(" ")[0];
      this.lastName = accountData["primaryUser"]["fullname"].split(" ")[1];
      this.fullName = accountData["primaryUser"]["fullname"];
      this.email = accountData["primaryUser"]["email"];
    } else {
      this.username = "Name not found";
      this.firstName = "Name Not Found";
      this.lastName = "";
      this.lastName = "";
      this.fullName = accountData["accountName"];
      this.email = "";
    }

    this.businessType = Object.prototype.hasOwnProperty.call(
      accountData,
      "mvdsCustomerType"
    )
      ? accountData["mvdsCustomerType"]["description"]
      : "";
    this.businessName =
      Object.prototype.hasOwnProperty.call(accountData, "vtrsCustomer") &&
      Object.prototype.hasOwnProperty.call(
        accountData["vtrsCustomer"],
        "businessCustomer"
      )
        ? accountData["vtrsCustomer"]["businessCustomer"]["businessName"]
        : "";

    if (Object.prototype.hasOwnProperty.call(accountData, "vtrsCustomer")) {
      this.phoneNumber = accountData["vtrsCustomer"]["contactPhone"];
      this.addressLine1 =
        accountData["vtrsCustomer"]["physicalAddress"]["streetNo"] +
        " " +
        accountData["vtrsCustomer"]["physicalAddress"]["address1"];
      this.addressLine2 = "";
      this.city = accountData["vtrsCustomer"]["physicalAddress"]["city"];
      this.state = accountData["vtrsCustomer"]["physicalAddress"]["state"];
      this.zipCode = accountData["vtrsCustomer"]["physicalAddress"]["zip5"];
      this.county = accountData["vtrsCustomer"]["physicalAddress"]["countyID"];
      this.fullAddress =
        this.addressLine1 +
        " " +
        this.addressLine2 +
        ", " +
        this.city +
        ", " +
        this.state +
        " " +
        this.zipCode;
    } else {
      this.phoneNumber = "";
      this.addressLine1 = "";
      this.addressLine2 = "";
      this.city = "";
      this.state = "";
      this.zipCode = "";
      this.county = "";
      this.fullAddress = "";
    }

    this.statusCode = accountData["statusCode"];
    switch (accountData["statusCode"]) {
      case "P":
        this.status = "Pending";
        break;
      case "E":
        this.status = "Edit";
        break;
      case "A":
        this.status = "Active";
        break;
      case "D":
        this.status = "Inactive";
        break;
      default:
        this.status = "Status Not Found";
        break;
    }

    this.isFreeAccount = accountData["isFreeAccount"];
  }

  id: string;
  userId: string;
  routingNo: string;
  accountName: string;
  accountNo: string;
  accountLastUpdateDate: string;
  lastLogin: string;
  displayDescription: string;
  username: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  businessType: string;
  businessName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  county: string;
  fullAddress: string;
  statusCode: string;
  status: string;
  isFreeAccount: string;
}
