






























































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Account } from "@/assets/classes/Account.ts";
import { Reason } from "@/assets/classes/Reason.ts";
import { SearchHistoryEntry } from "@/assets/classes/SearchHistory.ts";
import { ValidMimeTypes } from "@/assets/classes/ValidMimeTypes.ts";
import { ContentFile } from "@/types/index.ts";
import RequestHandler from "@/assets/ts/requestHandler.ts";
import DocumentationHandler from "@/assets/ts/documentationHandler.ts";

const RH = new RequestHandler();
const DH = new DocumentationHandler();

@Component
export default class Administration extends Vue {
  private accounts: Account[] = [];
  private selectedAccount = {} as Account;

  private employer = {
    id: -1,
    name: "",
    businessType: -1,
    businessTypeBoxItems: [] as {}[],
    searchCountAll: 0,
    searchCountThirtyDays: 0,
    isFreeAccount: "No",
    employees: [] as { [key: string]: string | boolean }[],
    filteredEmployees: [] as { [key: string]: string | boolean }[]
  };

  private accountSearchText = "";
  private employeeSearchText = "";
  private emailErrorMessage = "";

  private documentDialog = false;
  private reasonDialog = false;
  private employeeDialog = false;
  private rejectingAccount = false;
  private useSecondaryIframe = true;

  private reasonDialogData = {
    type: "",
    header: "",
    text: "",
    reasons: [] as string[],
    selectedReason: ""
  };

  private masterReasonList = {} as any;
  private rejectionReasons: string[] = [];
  private deactivationReasons: string[] = [];
  private editRequiredReasons: string[] = [];
  private expandedAccounts = [];

  private accountTableHeaders = [
    {
      text: "Username",
      value: "username"
    },
    {
      text: "Company Name",
      value: "businessName"
    },
    {
      text: "Owner Name",
      value: "fullName"
    },
    {
      text: "Status",
      value: "status"
    },
    {
      text: "Documentation",
      value: "documentation"
    },
    {
      text: "Actions",
      value: "actions"
    },
    {
      text: "More Info",
      value: "data-table-expand"
    }
  ];

  private employeeTableHeaders = [
    {
      text: "Username",
      value: "username"
    },
    {
      text: "Employee Name",
      value: "name"
    },
    {
      text: "Total Searches in Last 30 Days",
      value: "searchCountThirtyDays"
    },
    {
      text: "Total Searches",
      value: "searchCountAll"
    },
    {
      text: "Status",
      value: "status"
    },
    {
      text: "Actions",
      value: "actions"
    }
  ];

  private document = {
    mvdsCustomerID: "",
    name: "",
    url: "",
    newFile: [] as any,
    docOrDocx: false,
    docErrorMessage: "",
    docHistory: [] as any,
    docHistorySelection: ""
  };

  private statusFilter = "Pending";
  private statusBoxItems = ["Pending", "Edit", "Active", "Inactive", "All"];

  private employeeStatusFilter = "All";
  private employeeStatusBoxDisabled = false;
  private employeeStatusBoxItems = [
    "All",
    "Active",
    "Inactive",
    "Deactivated by Admin",
    "Removed"
  ];

  private async showDocument(type: string, mvdsCustomerID: string) {
    try {
      this.clearDocument();
      this.document.mvdsCustomerID = mvdsCustomerID;
      await this.loadDocHistory(type);
      if (type === "businessLicense") {
        this.document.name = "Business License";
      } else {
        this.document.name = "Statement on Nature of Business";
      }
      const base64 = await RH.downloadDocument(Number(mvdsCustomerID), type);
      this.document.docOrDocx = DH.checkForWordDoc(base64);
      this.document.url = !this.document.docOrDocx ? base64 : "";

      this.document.url = !this.document.docOrDocx ? base64 : "";
    } catch (e) {
      this.$root.$emit("snackbar-message", "Document retrieval failed");
    }
    this.documentDialog = true;
  }

  private async loadDocHistory(type: string) {
    try {
      this.document.docHistory = await RH.retrieveDocumentDirectory(
        Number(this.document.mvdsCustomerID),
        type
      );
      let i = 1;
      this.document.docHistory.forEach((element: any) => {
        if (element.statusCode === "A") {
          element.filename =
            element.filename +
            "-" +
            i +
            " (" +
            element.createdDate.slice(0, 4) +
            ")";
        }
        i++;
      });
      this.document.docHistorySelection = this.document.docHistory[0].documentID;
    } catch (e) {
      this.document.docHistory = [];
      this.$root.$emit(
        "snackbar-message",
        "Document History Retrieval Failure"
      );
    }
  }

  private async uploadDocument() {
    const docType =
      this.document.name == "Business License"
        ? "businessLicense"
        : "statement";
    if (this.document.newFile.length !== 0) {
      if (ValidMimeTypes.types.includes(this.document.newFile.type)) {
        try {
          await RH.uploadDocument(
            Number(this.document.mvdsCustomerID),
            this.document.newFile,
            docType
          );
          const base64 = await RH.downloadDocument(
            Number(this.document.mvdsCustomerID),
            docType
          );
          this.document.docOrDocx = DH.checkForWordDoc(base64);
          this.document.url = !this.document.docOrDocx ? base64 : "";
          this.$root.$emit(
            "snackbar-message",
            "Document Uploaded Successfully"
          );
        } catch (e) {
          this.$root.$emit("snackbar-message", "Document Upload Failed");
        }
        await this.loadDocHistory(docType);
      } else {
        this.document.docErrorMessage = "This file type is not valid";
      }
    }
  }

  private async downloadDoc() {
    this.document.url = "";
    const document = this.document.docHistory.find((element: any) => {
      return element.documentID === this.document.docHistorySelection;
    });
    this.document.url = document.contents;
  }

  private clearDocument() {
    Object.assign(this.document, {
      mvdsCustomerID: "",
      name: "",
      url: "",
      newFile: [],
      docOrDocx: false,
      docHistory: []
    });
  }

  private async changeDocument() {
    const document = this.document.docHistory.find((element: any) => {
      return element.documentID === this.document.docHistorySelection;
    });
    const base64 = document.contents;
    this.document.docOrDocx = DH.checkForWordDoc(base64);
    this.document.url = !this.document.docOrDocx ? base64 : "";
  }

  private async setAccounts(): Promise<void> {
    const statusMap = new Map([
      ["All", "All"],
      ["Pending", "P"],
      ["Edit", "E"],
      ["Active", "A"],
      ["Inactive", "D"]
    ]);
    const status = (statusMap.has(this.statusFilter)
      ? statusMap.get(this.statusFilter)
      : "N/A") as string;

    this.accounts = [];
    try {
      if (status == "All") {
        this.$root.$emit("start-loading");
        let tempAccList = await RH.getAccounts("P");
        if (tempAccList) {
          tempAccList.forEach((account: { [key: string]: string }) => {
            this.accounts.push(new Account(account));
          });
        }
        tempAccList = await RH.getAccounts("E");
        if (tempAccList) {
          tempAccList.forEach((account: { [key: string]: string }) => {
            this.accounts.push(new Account(account));
          });
        }
        tempAccList = await RH.getAccounts("D");
        if (tempAccList) {
          tempAccList.forEach((account: { [key: string]: string }) => {
            this.accounts.push(new Account(account));
          });
        }
        tempAccList = await RH.getAccounts("A");
        if (tempAccList) {
          tempAccList.forEach((account: { [key: string]: string }) => {
            this.accounts.push(new Account(account));
          });
        }
      } else {
        const tempAccList = await RH.getAccounts(status);
        if (tempAccList) {
          tempAccList.forEach((account: { [key: string]: string }) => {
            this.accounts.push(new Account(account));
          });
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.$root.$emit("stop-loading");
    }
  }

  private async setStatusCodeReasons(): Promise<void> {
    try {
      this.masterReasonList = (await RH.getStatusCodeReasons()) as any;
      const tempRejection = this.masterReasonList["MVDSDenialReason"];
      const tempDeactivation = this.masterReasonList["MVDSDeactivateReason"];
      const tempEditRequired = this.masterReasonList["MVDSEditRequiredReason"];
      if (tempRejection) {
        tempRejection.forEach((reason: { [key: string]: string }) => {
          this.rejectionReasons.push(reason.displayDescription);
        });
      }
      if (tempDeactivation) {
        tempDeactivation.forEach((reason: { [key: string]: string }) => {
          this.deactivationReasons.push(reason.displayDescription);
        });
      }
      if (tempEditRequired) {
        tempEditRequired.forEach((reason: { [key: string]: string }) => {
          this.editRequiredReasons.push(reason.displayDescription);
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  private getReasonLookupCode(lookupType: string): string {
    const tempRejection = this.masterReasonList[lookupType];
    const reasonData = tempRejection.find(
      (reason: any) =>
        reason.displayDescription === this.reasonDialogData.selectedReason
    );
    return reasonData
      ? reasonData.lookupCode
      : tempRejection.find(
          (reason: any) => reason.lookupDescription === "other"
        ).lookupCode;
  }

  public async approveAccount(account: Account) {
    this.expandedAccounts = [];
    this.clearEmployerData();
    try {
      await RH.handleAccount(account.id, account.statusCode, "A");
      this.$root.$emit(
        "snackbar-message",
        "Approved user named " + account.fullName + "."
      );
      this.setAccounts();
    } catch (e) {
      if (e.response.data.code === 409) {
        this.showPreviouslyUpdatedError();
      } else {
        this.$root.$emit(
          "snackbar-message",
          "Error approving user named " + account.fullName + "."
        );
      }
      this.setAccounts();
      console.error(e);
    }
  }

  private openReasonDialog(account: Account, dialogType: string) {
    this.expandedAccounts = [];
    this.clearEmployerData();
    this.reasonDialogData.type = dialogType;
    this.selectedAccount = account;
    if (this.reasonDialogData.type === "Reject") {
      this.reasonDialogData.header = "Rejection";
      this.reasonDialogData.text =
        "Please choose a reason for rejecting the following account: " +
        this.selectedAccount.businessName;
      this.reasonDialogData.reasons = this.rejectionReasons;
    } else if (this.reasonDialogData.type === "Edit") {
      this.reasonDialogData.header = "Request Edit";
      this.reasonDialogData.text =
        "Please choose a reason for requesting an edit on the following account: " +
        this.selectedAccount.businessName;
      this.reasonDialogData.reasons = this.rejectionReasons;
    } else {
      this.reasonDialogData.header = "Deactivation";
      this.reasonDialogData.text =
        "Please choose a reason for deactivating the following account: " +
        this.selectedAccount.businessName;
      this.reasonDialogData.reasons = this.deactivationReasons;
    }
    this.reasonDialog = true;
  }

  private closeReasonDialog() {
    this.reasonDialogData.selectedReason = "";
    this.selectedAccount = {} as Account;
    this.reasonDialog = false;
  }

  public async rejectAccount() {
    try {
      const comboBox = this.$refs.reasonComboBox as HTMLElement;
      await comboBox.blur();

      const lookupType = "MVDSDenialReason";
      const lookupCode = this.getReasonLookupCode(lookupType);
      await RH.handleAccount(
        this.selectedAccount.id,
        this.selectedAccount.statusCode,
        "D",
        this.reasonDialogData.selectedReason,
        lookupType,
        lookupCode
      );
      this.$root.$emit(
        "snackbar-message",
        "Rejected user named " + this.selectedAccount.fullName + "."
      );
      this.setAccounts();
    } catch (e) {
      if (e.response.data.code === 409) {
        this.showPreviouslyUpdatedError();
      } else {
        this.$root.$emit(
          "snackbar-message",
          "Error rejecting user named " + this.selectedAccount.fullName + "."
        );
      }
      this.setAccounts();
      console.error(e);
    } finally {
      this.closeReasonDialog();
    }
  }

  public async requestAccountEdit() {
    try {
      const comboBox = this.$refs.reasonComboBox as HTMLElement;
      await comboBox.blur();

      const lookupType = "MVDSEditRequiredReason";
      const lookupCode = this.getReasonLookupCode(lookupType);
      await RH.handleAccount(
        this.selectedAccount.id,
        this.selectedAccount.statusCode,
        "E",
        this.reasonDialogData.selectedReason,
        lookupType,
        lookupCode
      );
      this.$root.$emit(
        "snackbar-message",
        "Requested edit for user named " + this.selectedAccount.fullName + "."
      );
      this.setAccounts();
    } catch (e) {
      if (e.response.data.code === 409) {
        this.showPreviouslyUpdatedError();
      } else {
        this.$root.$emit(
          "snackbar-message",
          "Error requesting edit for user named " +
            this.selectedAccount.fullName +
            "."
        );
      }
      this.setAccounts();
      console.error(e);
    } finally {
      this.closeReasonDialog();
    }
  }

  private async deactivateAccount() {
    const comboBox = this.$refs.reasonComboBox as HTMLElement;
    await comboBox.blur();

    const lookupType = "MVDSDeactivateReason";
    const lookupCode = this.getReasonLookupCode(lookupType);
    try {
      await RH.handleAccount(
        this.selectedAccount.id,
        this.selectedAccount.statusCode,
        "D",
        this.reasonDialogData.selectedReason,
        lookupType,
        lookupCode
      );
      this.$root.$emit(
        "snackbar-message",
        "Successfully deactivated user named " +
          this.selectedAccount.fullName +
          "."
      );
      this.setAccounts();
    } catch (e) {
      if (e.response.data.code === 409) {
        this.showPreviouslyUpdatedError();
      } else {
        this.$root.$emit(
          "snackbar-message",
          "Error deactivating user named " + this.selectedAccount.fullName + "."
        );
      }
      this.setAccounts();
      console.error(e);
    } finally {
      this.closeReasonDialog();
    }
  }

  private async activateAccount(account: Account) {
    this.expandedAccounts = [];
    this.clearEmployerData();
    try {
      await RH.handleAccount(account.id, account.statusCode, "A");
      this.$root.$emit(
        "snackbar-message",
        "Successfully activated user named " + account.fullName + "."
      );
      this.setAccounts();
    } catch (e) {
      if (e.response.data.code === 409) {
        this.showPreviouslyUpdatedError();
      } else {
        this.$root.$emit(
          "snackbar-message",
          "Error activating user named " + account.fullName + "."
        );
      }
      this.setAccounts();
      console.error(e);
    }
  }

  private showPreviouslyUpdatedError() {
    this.$root.$emit(
      "snackbar-message",
      "User status has already been updated by another administrator."
    );
  }

  private async loadEmployerData({ item }: any) {
    if (this.employer.id !== item.id) {
      this.employer.id = -1;
      try {
        this.clearEmployerData();
        const allSearches = await RH.getAllSearches(item.id);
        const startDate = new Date();
        const endDate = new Date();
        startDate.setDate(endDate.getDate() - 30);
        const thirtyDaysSearches = await RH.getSearchesRange(
          item.id,
          startDate.toISOString().substr(0, 10),
          endDate.toISOString().substr(0, 10)
        );
        this.employer.name = item.fullName;
        const businessTypeObj = this.employer.businessTypeBoxItems.find(
          (type: any) => type.description === item.businessType
        ) as any;
        this.employer.businessType = businessTypeObj?.mvdsCustomerTypeID;
        this.employer.isFreeAccount =
          item.isFreeAccount === true ? "Yes" : "No";
        this.employer.searchCountAll = allSearches.length;
        this.employer.searchCountThirtyDays = thirtyDaysSearches.length;
        this.employer.employees = [];
        let tempEmpList = await RH.getEmployees(item.id);
        this.employeeStatusBoxDisabled = item.status !== "Active";
        tempEmpList = tempEmpList.filter(
          (employee: any) =>
            employee["mvdsCustomer"]["primaryUser"]["id"] !=
            employee["user"]["id"]
        );

        let tempEmp: { [key: string]: any } = {};
        tempEmpList.forEach((employee: any) => {
          tempEmp = {};
          tempEmp["username"] = employee["user"]["username"];
          tempEmp["id"] = employee["user"]["id"];
          tempEmp["name"] = employee["user"]["fullname"];
          this.setEmployeeStatus(tempEmp, employee["statusCode"], item.status);
          if (this.employer.searchCountAll > 0) {
            tempEmp["searchCountAll"] = allSearches.filter((search: any) => {
              return search.userID == employee["user"]["id"];
            }).length;
          }
          if (this.employer.searchCountThirtyDays > 0) {
            tempEmp["searchCountThirtyDays"] = thirtyDaysSearches.filter(
              (search: any) => {
                return search.userID == employee["user"]["id"];
              }
            ).length;
          }
          this.employer.employees.push(tempEmp);
        });
        this.employer.filteredEmployees = this.employer.employees;
        this.employer.id = item.id;
      } catch (e) {
        this.clearEmployerData();
        this.$root.$emit(
          "snackbar-message",
          "Error loading employer Information"
        );
      }
    }
  }

  private setEmployeeStatus(
    employee: { [key: string]: any },
    statusCode: string,
    employerStatus = "Active"
  ) {
    if (employerStatus !== "Active") {
      employee.status = "Customer is not Active";
    } else {
      const statusMap = new Map([
        ["A", "Active"],
        ["D", "Deactivated by Admin"],
        ["I", "Inactive"],
        ["R", "Removed"]
      ]);
      employee.status = statusMap.has(statusCode)
        ? statusMap.get(statusCode)
        : "N/A";
    }
  }

  private clearEmployerData() {
    this.employer.id = 0;
    this.employer.name = "";
    this.employer.searchCountAll = 0;
    this.employer.searchCountThirtyDays = 0;
    this.employer.employees = [];
  }

  private async updateEmployeeStatus(
    employee: { [key: string]: any },
    statusCode: string
  ) {
    try {
      await RH.handleEmployee(employee["id"], statusCode);
      this.setEmployeeStatus(employee, statusCode);
      this.$root.$emit(
        "snackbar-message",
        "Successfully altered employee named " + employee["name"] + "."
      );
    } catch (e) {
      this.$root.$emit(
        "snackbar-message",
        "Error altering employee named " + employee["name"] + "."
      );
    }
  }

  private filterEmployees() {
    if (this.employeeStatusFilter === "All") {
      this.employer.filteredEmployees = this.employer.employees;
    } else {
      this.employer.filteredEmployees = this.employer.employees.filter(
        employee => employee["status"] === this.employeeStatusFilter
      );
    }
  }

  private async updateBusinessType(mvdsCustomerID: any) {
    try {
      const customer = await RH.getCustomer(mvdsCustomerID);
      customer.mvdsCustomerType.mvdsCustomerTypeID = this.employer.businessType;
      await RH.editCustomer(customer);
      this.$root.$emit("snackbar-message", "Business type updated.");
    } catch (e) {
      this.$root.$emit("snackbar-message", "Business type update failed.");
    }
  }

  private async updateFreeAccountStatus(mvdsCustomerID: any) {
    try {
      const customer = await RH.getCustomer(mvdsCustomerID);
      customer.isFreeAccount =
        this.employer.isFreeAccount === "Yes" ? true : false;
      await RH.editCustomer(customer);
      this.$root.$emit("snackbar-message", "Account status updated.");
    } catch (e) {
      this.$root.$emit("snackbar-message", "Account status update failed.");
    }
  }

  private async updateEmail(mvdsCustomerID: any, userID: any, email: string) {
    this.emailErrorMessage = "";
    const emailRegex = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (!emailRegex.test(email)) {
      this.emailErrorMessage = "Please enter a valid email address.";
    } else {
      const emailExists = await RH.checkForExistence("email", email);
      if (emailExists) {
        this.emailErrorMessage = "Email is taken. Please choose another.";
      } else {
        try {
          const customer = await RH.getCustomer(mvdsCustomerID);
          customer.vtrsCustomer.customerEMail = email;
          customer.primaryUser.email = email;
          await RH.editCustomer(customer);
          this.$root.$emit("snackbar-message", "Email updated.");
        } catch (e) {
          this.$root.$emit("snackbar-message", "Email update failed.");
        }
      }
    }
  }

  async created() {
    await this.setAccounts();
    await this.setStatusCodeReasons();
    this.employer.businessTypeBoxItems = await RH.getCustomerTypes();
  }
}
